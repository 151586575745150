import React from 'react';
import { SelfOnboardingStepProps } from 'containers/selfOnboarding/steps/types';
import { GradientCard, StepInfo } from 'containers/selfOnboarding/steps/components';
import { StepJobsiteInfo } from 'containers/selfOnboarding/steps/components/StepJobsiteInfo';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import { Copy } from 'containers/selfOnboarding/steps/utils';

export function JobsiteWorkerWithDifferentContractorStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, jobsiteInvitation, localize } = props;
  const { contractorId } = state;
  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo showOnMobileOnly>
        <StepJobsiteInfo jobsiteInvitation={jobsiteInvitation} contractorName={contractorId?.label} />
      </StepInfo>
      <div className={commonClasses.stepHeader}>
        <GradientCard
          headerText={localize(Copy.self_onboarding_jobsite_worker_with_different_contractor_heading)}
          text={localize(Copy.self_onboarding_jobsite_worker_with_different_contractor_text)}
        />
      </div>
    </div>
  );
}
