import React from 'react';
import moment from 'moment';
import { DeepMap } from 'react-hook-form';
import { useDidUpdateEffect } from '@odin-labs/components';
import {
  SelfOnboardingWorkerAccountCreateInput,
  UserIdentityInput,
  UpdateWorkerMutationVariables,
  UserIdentity,
  JobsiteWorkerDocumentUpsertInput,
  DocumentKey,
  FileChangeInput,
  ChangeType,
} from 'apollo/generated/client-operations';
import { EnvelopeIcon } from 'components/icons';
import {
  FormInputTypes,
  TypedFormInputs,
  getUpdateInputValueFunction,
  GridColSpan,
  UseInputs,
  UseFormMethods,
} from 'components/form';
import { camelToKebabCase, ensureNonUndefinedFields, getLocalIp, getPhoneNumberAsE164, isNotEmpty } from 'utils';
import { momentFormatter } from 'utils/dates';
import { ageValidation, dateValidation, emailValidation, phoneNumberValidation, ssnValidation } from 'utils/validation';
import {
  AcknowledgmentOption,
  AcknowledgmentStatus,
  FormInputArgs,
  JobsiteInvitation,
  Localize,
  SelfOnboardingBasicInfoFormData,
  SelfOnboardingState,
  SelfOnboardingStateWorker,
  SelfOnboardingStepKey,
  SelfOnboardingStepProps,
} from 'containers/selfOnboarding/steps/types';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { getAcknowledgmentFile } from 'containers/selfOnboarding/steps/documentStep/DocumentStep.forms';

type GetAcknowledgmentOptions = {
  isWorkerConsentDisabled: boolean;
  workerConsentDocumentName: string;
  localize: Localize;
  goToWorkerConsentDocumentStep: () => void;
};

const getAcknowledgmentOptions = (args: GetAcknowledgmentOptions): AcknowledgmentOption[] => {
  const { isWorkerConsentDisabled, workerConsentDocumentName, goToWorkerConsentDocumentStep, localize } = args;

  return [
    {
      value: AcknowledgmentStatus.Confirmed,
      label: (
        <div className="odin-ml-1">
          {localize(Copy.self_onboarding_worker_consent_text_start)} &nbsp;
          <a
            href="#"
            onClick={(ev): void => {
              ev.preventDefault();
              goToWorkerConsentDocumentStep();
            }}
          >
            {workerConsentDocumentName}
          </a>
          &nbsp; {localize(Copy.self_onboarding_worker_consent_text_end)}
        </div>
      ),
      disabled: isWorkerConsentDisabled,
    },
  ];
};

export type BasicInfoEditableFields = Omit<
  Record<keyof SelfOnboardingBasicInfoFormData, boolean>,
  'middleInitial' | 'suffix' | 'workerConsentStatus'
>;

export const getEditableFields = (args: {
  worker: SelfOnboardingStateWorker;
  values: SelfOnboardingBasicInfoFormData;
  verificationPhoneNumber: string;
}): BasicInfoEditableFields => {
  const { worker, values, verificationPhoneNumber } = args;
  const { isAssignedToMultipleJobsites, editableFields: workerEditableFields } = worker ?? {};

  const isEmailEditable = !isAssignedToMultipleJobsites && (!workerEditableFields || workerEditableFields.email);
  const isPhoneEditable =
    !verificationPhoneNumber && !isAssignedToMultipleJobsites && (!workerEditableFields || workerEditableFields.phone);

  return {
    firstName: !values.firstName || !isAssignedToMultipleJobsites,
    lastName: !values.lastName || !isAssignedToMultipleJobsites,
    birthDate: !values.birthDate || !isAssignedToMultipleJobsites,
    ssnLastFour: !values.ssnLastFour || !isAssignedToMultipleJobsites,
    email: !values.email || isEmailEditable,
    phoneNumber: !values.phoneNumber || isPhoneEditable,
  };
};

type GetFormInputsArgs = FormInputArgs &
  Pick<GetAcknowledgmentOptions, 'isWorkerConsentDisabled' | 'goToWorkerConsentDocumentStep'> & {
    acknowledgmentOptions: AcknowledgmentOption[];
    editableFields: BasicInfoEditableFields;
    isWorkerConsentAvailable: boolean;
    workerConsentDocumentName: string;
  };

const getFormInputs = (args: GetFormInputsArgs): TypedFormInputs<SelfOnboardingBasicInfoFormData> => {
  const { isWorkerConsentAvailable, editableFields, acknowledgmentOptions } = args;

  return {
    firstName: {
      element: FormInputTypes.Field,
      label: 'First name',
      elementProps: { disabled: !editableFields.firstName },
      validation: { required: true },
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    middleInitial: {
      element: FormInputTypes.Field,
      label: 'Middle initial',
      elementProps: {
        fieldType: 'custom',
        cleaveOptions: { blocks: [1] },
      },
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    lastName: {
      element: FormInputTypes.Field,
      label: 'Last name',
      elementProps: { disabled: !editableFields.lastName },
      validation: { required: true },
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    suffix: {
      element: FormInputTypes.Field,
      label: 'Suffix',
      layout: [GridColSpan.Span6, GridColSpan.SmSpan6],
    },
    birthDate: {
      element: FormInputTypes.Field,
      label: 'Date of birth',
      elementProps: {
        placeholder: 'MM/DD/YYYY',
        fieldType: 'pastDate',
        inputMode: 'numeric',
        disabled: !editableFields.birthDate,
      },
      validation: { required: true, pattern: dateValidation, validate: ageValidation('Date of birth', 14, 100) },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    ssnLastFour: {
      element: FormInputTypes.Field,
      label: 'Last 4 of social security number',
      elementProps: {
        fieldType: 'ssn4',
        inputMode: 'numeric',
        disabled: !editableFields.ssnLastFour,
      },
      validation: { pattern: ssnValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    phoneNumber: {
      element: FormInputTypes.Field,
      label: 'Phone number',
      elementProps: {
        fieldType: 'phone',
        inputMode: 'numeric',
        showDefaultIcon: true,
        disabled: !editableFields.phoneNumber,
      },
      validation: { pattern: phoneNumberValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    email: {
      element: FormInputTypes.Field,
      label: 'Email address',
      elementProps: {
        disabled: !editableFields.email,
        icon: EnvelopeIcon,
      },
      validation: { pattern: emailValidation },
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
    },
    workerConsentStatus: {
      element: FormInputTypes.CheckList,
      isHidden: !isWorkerConsentAvailable,
      elementProps: { options: acknowledgmentOptions },
      layout: [GridColSpan.SpanFull, 'print:odin-hidden'],
    },
  };
};

type GetFormInputsHookArgs = Omit<
  GetFormInputsArgs,
  'goToWorkerConsentDocumentStep' | 'isWorkerConsentDisabled' | 'workerConsentDocumentName' | 'acknowledgmentOptions'
> &
  Pick<SelfOnboardingStepProps, 'updateState' | 'navigation' | 'localize'> & {
    isWorkerConsentConfirmed: boolean;
    setIsContinueActionEnabled: (value: boolean) => void;
  };

export const getFormInputsHook =
  (args: GetFormInputsHookArgs): UseInputs<SelfOnboardingBasicInfoFormData> =>
  (form: UseFormMethods<SelfOnboardingBasicInfoFormData>): TypedFormInputs<SelfOnboardingBasicInfoFormData> => {
    const {
      jobsiteInvitation,
      navigation: { goToStep },
      state: { worker, documentTypes },
      updateState,
      localize,
      editableFields,
      isWorkerConsentAvailable,
      isWorkerConsentConfirmed,
      setIsContinueActionEnabled,
    } = args;
    const { watch, getValues } = form;
    const { dirtyFields } = form?.formState ?? {};

    const goToWorkerConsentDocumentStep = React.useCallback(() => {
      if (isNotEmpty(dirtyFields)) {
        updateState({ basicInfo: getValues() });
      }
      goToStep(SelfOnboardingStepKey.WorkerConsentDocument);
    }, [goToStep, dirtyFields, getValues, updateState]);

    const workerConsentStatus = watch('workerConsentStatus');
    const isContinueActionEnabled = workerConsentStatus?.includes(AcknowledgmentStatus.Confirmed);
    useDidUpdateEffect(() => {
      setIsContinueActionEnabled(isContinueActionEnabled);
    }, [isContinueActionEnabled]);

    const isWorkerConsentDisabled = !!worker && isWorkerConsentConfirmed;
    const workerConsentDocumentName =
      documentTypes?.[DocumentKey.WorkerConsentDocument]?.acknowledgmentFile?.title ??
      'Worker Consent Agreement and Release';

    return React.useMemo(() => {
      const acknowledgmentOptions = getAcknowledgmentOptions({
        isWorkerConsentDisabled,
        workerConsentDocumentName,
        localize,
        goToWorkerConsentDocumentStep,
      });

      return getFormInputs({
        jobsiteInvitation,
        editableFields,
        isWorkerConsentAvailable,
        isWorkerConsentDisabled,
        workerConsentDocumentName,
        acknowledgmentOptions,
        goToWorkerConsentDocumentStep,
      });
    }, [
      jobsiteInvitation,
      editableFields,
      isWorkerConsentAvailable,
      isWorkerConsentDisabled,
      workerConsentDocumentName,
      localize,
      goToWorkerConsentDocumentStep,
    ]);
  };

export const getDefaultValues = (basicInfo: SelfOnboardingBasicInfoFormData): SelfOnboardingBasicInfoFormData => {
  const {
    firstName,
    lastName,
    email,
    middleInitial,
    suffix,
    birthDate,
    phoneNumber,
    ssnLastFour,
    workerConsentStatus,
  } = basicInfo ?? {};

  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    email: email ?? '',
    middleInitial: middleInitial ?? '',
    suffix: suffix ?? '',
    birthDate: birthDate ?? '',
    phoneNumber: phoneNumber ?? '',
    ssnLastFour: ssnLastFour ?? '',
    workerConsentStatus: workerConsentStatus ?? [],
  };
};

export const getCreateInput = ({
  jobsiteInvitation,
  contractorId,
  primaryLanguage,
  nextStep,
  data,
  dirtyFields,
}: {
  jobsiteInvitation: JobsiteInvitation;
  contractorId: string;
  primaryLanguage: string;
  nextStep: SelfOnboardingStepKey;
  data: SelfOnboardingBasicInfoFormData;
  dirtyFields: DeepMap<SelfOnboardingBasicInfoFormData, true>;
}): SelfOnboardingWorkerAccountCreateInput => {
  const { jobsiteInvitationId, jobsiteContractor } = jobsiteInvitation;
  const {
    jobsite: { jobsiteId },
    contractor: { contractorId: jobsiteInvitationContractorId },
  } = jobsiteContractor;
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const birthDateInputValue = dirtyFields.birthDate
    ? moment(data.birthDate, momentFormatter).toDate() || null
    : undefined;
  const phoneNumberInputValue = getPhoneNumberAsE164(getUpdateInputValue('phoneNumber'));

  return ensureNonUndefinedFields<SelfOnboardingWorkerAccountCreateInput>({
    jobsiteId,
    contractorId: contractorId ?? jobsiteInvitationContractorId,
    createUserInput: {
      firstName: getUpdateInputValue('firstName'),
      lastName: getUpdateInputValue('lastName'),
      email: getUpdateInputValue('email'),
      phoneNumber: phoneNumberInputValue,
    },
    workerInput: {
      middleInitial: getUpdateInputValue('middleInitial'),
      suffix: getUpdateInputValue('suffix'),
      primaryLanguage,
      selfOnboardingCurrentStep: nextStep,
      jobsiteInvitationId,
      workerBaseInfoInput: {
        birthDate: birthDateInputValue,
        ssnLastFour: getUpdateInputValue('ssnLastFour'),
      },
    },
  });
};

export const getWorkerConsentDocumentInput = async ({
  user,
  state,
  jobsiteInvitation,
  jobsiteWorkerId,
}: {
  user: { identity?: Pick<UserIdentity, 'firstName' | 'lastName'> };
  state: SelfOnboardingState;
  jobsiteInvitation: JobsiteInvitation;
  jobsiteWorkerId: string;
}): Promise<JobsiteWorkerDocumentUpsertInput> => {
  const { documentTypes } = state;
  const documentKey = DocumentKey.WorkerConsentDocument;
  const documentType = documentTypes[documentKey];
  const { acknowledgmentFile } = documentType ?? {};

  const isWorkerConsentConfirmedAlready = !!state.documents?.some(
    (doc) => doc.key === documentKey && doc.acknowledgmentDate,
  );

  if (isWorkerConsentConfirmedAlready) return null;

  const additionalFieldValues = [
    {
      key: camelToKebabCase('acknowledgmentStatus'),
      value: AcknowledgmentStatus.Confirmed,
      type: 'string',
    },
    {
      key: camelToKebabCase('acknowledgmentDate'),
      value: moment().format(),
      type: 'date',
    },
    {
      key: camelToKebabCase('acknowledgmentIpAddress'),
      value: await getLocalIp(),
      type: 'string',
    },
  ];

  const fileData = await getAcknowledgmentFile({ acknowledgmentFile, jobsiteInvitation, user });
  const acknowledgmentFileInput: FileChangeInput = {
    changeType: ChangeType.Created,
    fileInput: { uploadData: fileData, isPublic: false },
  };

  return {
    jobsiteWorkerId,
    jobsiteWorkerDocumentTypeId: documentType.id,
    key: documentKey,
    files: [acknowledgmentFileInput],
    additionalFieldValues,
  };
};

export type BasicInfoUpdateInput = { userIdentityInput: Omit<UserIdentityInput, 'userAccountId'> } & Omit<
  UpdateWorkerMutationVariables,
  'workerId'
>;

export const getUpdateInputs = (
  data: SelfOnboardingBasicInfoFormData,
  dirtyFields: DeepMap<SelfOnboardingBasicInfoFormData, true>,
): BasicInfoUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(data, dirtyFields);

  const birthDateInputValue = dirtyFields.birthDate
    ? moment.utc(data.birthDate, momentFormatter).toDate() || null
    : undefined;
  const phoneNumberInputValue = getPhoneNumberAsE164(getUpdateInputValue('phoneNumber'));

  return ensureNonUndefinedFields<BasicInfoUpdateInput>({
    workerInput: {
      middleInitial: getUpdateInputValue('middleInitial'),
      suffix: getUpdateInputValue('suffix'),
      workerBaseInfoInput: {
        birthDate: birthDateInputValue,
        ssnLastFour: getUpdateInputValue('ssnLastFour'),
      },
    },
    userIdentityInput: {
      firstName: getUpdateInputValue('firstName'),
      lastName: getUpdateInputValue('lastName'),
      email: getUpdateInputValue('email'),
      phoneNumber: phoneNumberInputValue,
    },
  });
};
