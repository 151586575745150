import React from 'react';
import { Form, FormOnSubmit } from 'components/form';
import { AppErrorCode, useStartPhoneNumberVerificationMutation } from 'apollo/generated/client-operations';
import { AlertService } from 'components/alertService';
import { getGraphQLError, hasGraphQLErrorCode } from 'utils/error';
import { commonClasses } from 'containers/selfOnboarding/steps/common.style';
import {
  SelfOnboardingStepKey,
  SelfOnboardingStepProps,
  SelfOnboardingVerificationPhoneNumberFormData,
} from 'containers/selfOnboarding/steps/types';
import { StepInfo, StepActions, StepInfoTitle, StepJobsiteInfo } from 'containers/selfOnboarding/steps/components';
import { Copy } from 'containers/selfOnboarding/steps/utils';
import { useSelfOnboardingFormCommonProps } from 'containers/selfOnboarding/helpers/forms';
import { getPhoneNumberAsE164 } from 'utils';
import { getDefaultValues, getFormInputs } from './LoginStartStep.forms';

export function LoginStartStep(props: SelfOnboardingStepProps): React.ReactElement {
  const { state, updateState, navigation, stepConfig, localize, language, jobsiteInvitation } = props;
  const { verificationPhoneNumber, basicInfo, contractorId } = state;
  const phoneNumber = verificationPhoneNumber ?? basicInfo?.phoneNumber;
  const { goToNextStep, goToStep, getStepNumberInfo, state: navigationState } = navigation;
  const { isReview, autoFocusField } = navigationState;
  const stepNumberInfo = getStepNumberInfo();

  const [isSaving, setIsSaving] = React.useState(false);
  const [startPhoneNumberVerification] = useStartPhoneNumberVerificationMutation();

  const onSubmit: FormOnSubmit<SelfOnboardingVerificationPhoneNumberFormData> = async (data, event): Promise<void> => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      updateState({ ...data, basicInfo: { ...basicInfo, phoneNumber: data.verificationPhoneNumber } });
      const result = await startPhoneNumberVerification({
        variables: { input: { isLogin: true, phoneNumber: getPhoneNumberAsE164(data.verificationPhoneNumber) } },
      });

      setIsSaving(false);
      if (result.data.startPhoneNumberVerification.success) {
        goToStep(SelfOnboardingStepKey.ConfirmLoginCode);
      }
    } catch (error) {
      event.preventDefault();
      setIsSaving(false);
      if (hasGraphQLErrorCode(error, AppErrorCode.StartPhoneNumberVerification, AppErrorCode.ResourceNotFound)) {
        goToStep(SelfOnboardingStepKey.BasicInfo);
      } else {
        AlertService.alert('danger', 'Something went wrong!', getGraphQLError(error));
      }
    }
  };

  const inputs = React.useMemo(() => getFormInputs(), []);
  const defaultValues = React.useMemo(() => getDefaultValues({ verificationPhoneNumber: phoneNumber }), [phoneNumber]);
  const { fieldsConfig, localization } = useSelfOnboardingFormCommonProps(stepConfig, localize);

  return (
    <div className={commonClasses.stepContainer}>
      <StepInfo>
        <StepInfoTitle
          title={localize(Copy.self_onboarding_login_header)}
          subtitle={localize(Copy.self_onboarding_login_instructions)}
          stepNumberInfo={stepNumberInfo}
          withBottomBorder
        />
        <StepJobsiteInfo jobsiteInvitation={jobsiteInvitation} contractorName={contractorId?.label} />
      </StepInfo>
      <Form
        inputs={inputs}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        autoFocus={(autoFocusField as keyof SelfOnboardingVerificationPhoneNumberFormData) ?? true}
        renderBelow={
          <StepActions
            jobsiteInvitation={jobsiteInvitation}
            localize={localize}
            isReview={isReview}
            actions="continue"
            continueActionWithSpinner={isSaving}
            onSkip={goToNextStep}
            ignoreJobsiteConfigSkipFlag
          />
        }
        validationTriggers={[language]}
        className={commonClasses.form}
        inputsContainerClassName={commonClasses.formInputsContainer}
        fieldsConfig={fieldsConfig}
        localization={localization}
      />
    </div>
  );
}
