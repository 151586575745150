import { SelectOptionElement } from '@odin-labs/components';
import {
  CCureEntityType,
  HidProxyInstance,
  JobsiteUpdateInput,
  WorkerCardType,
} from 'apollo/generated/client-operations';
import { FormInputTypes, TypedFormInputs } from 'components/form';
import {
  Jobsite,
  CCureEntityMapping,
  EditJobsiteConfigurationFormData,
  CCureEntitySelectOptionElement,
  UserData,
  HidFargoSelectOptionElement,
  BadgeTemplateOptionElement,
} from 'containers/jobsiteConfiguration/types';
import {
  WorkerCardFormatOption,
  WorkerDocumentTypeOption,
  getCCureEnvironmentsOptions,
  getWorkerCardFormatOptions,
  getWorkerDocumentTypesOptions,
} from 'containers/worker/helpers/utils';
import { OnboardingStepKey } from 'containers/workerOnboarding/types';
import { DeepMap } from 'react-hook-form';
import slugify from 'slugify';
import { ensureNonUndefinedFields, isNotEmpty, titleToKebabCase } from 'utils';
import {
  getDevelopersOptions,
  getJobsiteOptions,
  getSessionDisplayColumnOptions,
} from 'containers/jobsites/helpers/utils';
import {
  getBadgeTemplateOptions,
  getCCureEntityMappingsOptions,
  getFormOptions,
  getHidFargoEntityOptions,
} from 'containers/jobsiteConfiguration/helpers/utils';
import {
  featuresSectionInputs,
  getCCureSectionInputsHook,
  getCCureSectionDefaultValues,
  getCCureSectionUpdateInput,
  geofencingSectionInputs,
  getDocumentsSectionInputsHook,
  getDocumentsSectionDefaultValues,
  getGeofencingSectionDefaultValues,
  getGeofencingSectionUpdateInput,
  getHidFargoConnectSectionInputsHook,
  getHidFargoConnectSectionDefaultValues,
  getHidFargoConnectSectionUpdateInput,
  getJobsiteInfoSectionInputs,
  getJobsiteInfoSectionDefaultValues,
  getNotificationsSectionDefaultValues,
  getOnboardingSectionDefaultValues,
  getProcoreSectionDefaultValues,
  getSiteAccessRulesSectionInputs,
  getSiteAccessRulesSectionDefaultValues,
  getSiteAccessRulesSectionUpdateInput,
  getWorkerInfoSectionDefaultValues,
  notificationsSectionInputs,
  onboardingSectionInputs,
  procoreSectionInputs,
  workerInfoSectionInputs,
  getNotificationsSectionUpdateInput,
  getJobsiteInfoSectionUpdateInput,
  getProcoreSectionUpdateInput,
  getOnboardingSectionUpdateInput,
  getWorkerInfoSectionUpdateInput,
  getOnboardingModuleDocumentInputs,
  siteSpecificOrientationSectionInputs,
  getSiteSpecificOrientationSectionUpdateInput,
  getSiteSpecificOrientationSectionDefaultValues,
  getFeaturesSectionDefaultValues,
  getFeaturesSectionUpdateInput,
  getOnboardingDocumentFilesInputs,
  cameraSectionInputs,
  getCameraSectionUpdateInput,
  getCameraSectionDefaultValues,
} from './forms';
import { hidProxyInstanceSelectOptions } from './forms/hidFargoConnectSection';
import {
  customFieldsSectionInputs,
  getCustomFieldsDefaultValues,
  getCustomFieldsSectionUpdateInput,
} from './forms/customFieldsSection';

export type JobsiteSelectOptions = {
  developersOptions: SelectOptionElement[];
  jobsitesOptions: SelectOptionElement[];
  workerCardFormatsOptions: WorkerCardFormatOption[];
  cCureEnvironmentsOptions: SelectOptionElement[];
  workerDocumentTypesOptions: WorkerDocumentTypeOption[];
  cCureClearancesOptions: CCureEntitySelectOptionElement[];
  cCureControllersOptions: CCureEntitySelectOptionElement[];
  cCureDoorsOptions: CCureEntitySelectOptionElement[];
  cCureInboundAreasOptions: CCureEntitySelectOptionElement[];
  cCureOutboundAreasOptions: CCureEntitySelectOptionElement[];
  sessionDisplayColumnOptions: SelectOptionElement[];
  hidProxyInstanceOptions: SelectOptionElement<HidProxyInstance>[];
  hidPrinterOptions: HidFargoSelectOptionElement[];
  hidLocationOptions: HidFargoSelectOptionElement[];
  hidOrganizationOptions: HidFargoSelectOptionElement[];
  badgeTemplateOptions: BadgeTemplateOptionElement[];
  formsOptions: SelectOptionElement[];
};

export const getDropdownsOptions = (args: { userData: UserData; jobsite: Jobsite }): JobsiteSelectOptions => {
  const { userData, jobsite } = args;
  const developers = userData?.developers.edges.map(({ node }) => node);
  const jobsites = userData?.jobsites.edges
    .filter(({ node }) => node?.jobsiteId !== jobsite?.jobsiteId) // filter out current jobsite
    .map(({ node }) => node);
  const workerCardFormats = userData?.workerCardFormats.edges
    .map(({ node }) => node)
    .filter((wcf) => wcf.cardType !== WorkerCardType.Bluetooth);
  const workerDocumentTypes = userData?.workerDocumentTypes.edges.map(({ node }) => node);
  const cCureEnvironments = userData?.cCureEnvironments.edges.map(({ node }) => node);
  const entityMappingsByType: Partial<Record<CCureEntityType, CCureEntityMapping[]>> =
    userData?.cCureEntityMappingOptions.edges.reduce(
      (groups: Record<CCureEntityType, CCureEntityMapping[]>, { node: mapping }) => {
        const { entityType } = mapping;
        groups[entityType] = groups[entityType] ?? []; // eslint-disable-line no-param-reassign
        groups[entityType].push(mapping);
        return groups;
      },
      {} as Record<CCureEntityType, CCureEntityMapping[]>,
    ) ?? {};
  const allHidEntityOptions = userData?.hidEntityOptions.edges.map(({ node }) => node) ?? [];
  const availableBadgeTemplates = userData?.badgeTemplates.edges.map(({ node }) => node) ?? [];
  const forms = userData?.forms.edges.map(({ node }) => node) ?? [];

  return {
    developersOptions: getDevelopersOptions(developers),
    jobsitesOptions: getJobsiteOptions(jobsites),
    workerCardFormatsOptions: getWorkerCardFormatOptions(workerCardFormats),
    workerDocumentTypesOptions: getWorkerDocumentTypesOptions(workerDocumentTypes),
    cCureEnvironmentsOptions: getCCureEnvironmentsOptions(cCureEnvironments),
    cCureClearancesOptions: getCCureEntityMappingsOptions(entityMappingsByType[CCureEntityType.Clearance], false),
    cCureControllersOptions: getCCureEntityMappingsOptions(entityMappingsByType[CCureEntityType.Controller]),
    cCureDoorsOptions: getCCureEntityMappingsOptions(entityMappingsByType[CCureEntityType.Door]),
    cCureInboundAreasOptions: getCCureEntityMappingsOptions(entityMappingsByType[CCureEntityType.InArea]),
    cCureOutboundAreasOptions: getCCureEntityMappingsOptions(entityMappingsByType[CCureEntityType.OutArea]),
    sessionDisplayColumnOptions: getSessionDisplayColumnOptions(),
    hidProxyInstanceOptions: hidProxyInstanceSelectOptions,
    hidOrganizationOptions: getHidFargoEntityOptions(allHidEntityOptions, 'Organization'),
    hidLocationOptions: getHidFargoEntityOptions(allHidEntityOptions, 'Location'),
    hidPrinterOptions: getHidFargoEntityOptions(allHidEntityOptions, 'Printer'),
    badgeTemplateOptions: getBadgeTemplateOptions(availableBadgeTemplates),
    formsOptions: getFormOptions(forms),
  };
};

type GetFormInputsArgs = JobsiteSelectOptions & {
  jobsite: Jobsite;
};

export const getFormInputs = (args: GetFormInputsArgs): TypedFormInputs<EditJobsiteConfigurationFormData> => {
  const {
    jobsite,
    developersOptions,
    jobsitesOptions,
    workerCardFormatsOptions,
    cCureEnvironmentsOptions,
    cCureClearancesOptions: clearanceOptions,
    cCureControllersOptions: controllerOptions,
    cCureDoorsOptions: doorOptions,
    cCureInboundAreasOptions: inboundAreaOptions,
    cCureOutboundAreasOptions: outboundAreaOptions,
    sessionDisplayColumnOptions,
    hidProxyInstanceOptions,
    hidOrganizationOptions,
    hidLocationOptions,
    hidPrinterOptions,
    badgeTemplateOptions,
    formsOptions,
  } = args;

  const jobsiteInfoSectionInputs = getJobsiteInfoSectionInputs({
    developersOptions,
    jobsitesOptions,
    formsOptions,
    isTemplate: jobsite?.isTemplate,
  });
  const documentsSectionInputs = getDocumentsSectionInputsHook();
  const siteAccessRulesSectionInputs = getSiteAccessRulesSectionInputs({ sessionDisplayColumnOptions });
  const cCureSectionInputs = getCCureSectionInputsHook({
    workerCardFormatsOptions,
    clearanceOptions,
    cCureEnvironmentsOptions,
    controllerOptions,
    doorOptions,
    inboundAreaOptions,
    outboundAreaOptions,
  });
  const hidFargoConnectSectionInputs = getHidFargoConnectSectionInputsHook({
    proxyInstanceOptions: hidProxyInstanceOptions,
    organizationOptions: hidOrganizationOptions,
    locationOptions: hidLocationOptions,
    printerOptions: hidPrinterOptions,
    badgeTemplateOptions,
  });

  return {
    jobsite: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Jobsite Info',
        childrenGridColumns: '4',
      },
      children: jobsiteInfoSectionInputs,
    },
    features: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Features',
        details: 'Feature toggles',
        verticalGap: 'sm',
      },
      children: featuresSectionInputs,
    },
    onboarding: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Onboarding',
        details: 'Set top-level rules for onboarding',
        verticalGap: 'sm',
      },
      children: onboardingSectionInputs,
    },
    workerInfo: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Worker Info',
        details: 'Indicate which fields are required or visible',
      },
      children: workerInfoSectionInputs,
    },
    siteSpecificOrientation: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Site-specific Orientation',
        details: 'Indicate which fields are required',
      },
      children: siteSpecificOrientationSectionInputs,
    },
    documents: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Documents',
        details: 'Indicate which fields are required',
      },
      children: documentsSectionInputs,
    },
    siteAccessRules: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Site Access Rules',
      },
      children: siteAccessRulesSectionInputs,
    },
    notifications: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Notifications',
        details: 'Set recipients for each notification type',
      },
      children: notificationsSectionInputs,
    },
    geofencing: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Geofencing',
        details: 'Add and manage geographical areas to accurately gate site access via mobile app',
      },
      children: geofencingSectionInputs,
    },
    hidFargoConnect: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'HID Fargo Connect',
      },
      children: hidFargoConnectSectionInputs,
    },
    cCure: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'C-CURE',
      },
      children: cCureSectionInputs,
    },
    cameras: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Cameras',
      },
      children: cameraSectionInputs,
    },
    procore: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Procore',
      },
      children: procoreSectionInputs,
    },
    customFields: {
      element: FormInputTypes.Section,
      layout: '',
      elementProps: {
        title: 'Custom Fields',
      },
      children: customFieldsSectionInputs(jobsite),
    },
  };
};

type GetDefaultValuesArgs = {
  jobsite: Jobsite;
} & Omit<JobsiteSelectOptions, 'cCureEnvironmentsOptions' | 'defaultProximityOptions' | 'defaultQrOptions'>;

export const getDefaultValues = (args: GetDefaultValuesArgs): EditJobsiteConfigurationFormData => {
  const {
    jobsite,
    developersOptions,
    jobsitesOptions,
    workerCardFormatsOptions,
    cCureControllersOptions,
    cCureDoorsOptions,
    cCureInboundAreasOptions,
    cCureOutboundAreasOptions,
    sessionDisplayColumnOptions,
    hidProxyInstanceOptions,
    hidOrganizationOptions,
    hidLocationOptions,
    hidPrinterOptions,
    badgeTemplateOptions,
    formsOptions,
  } = args;

  const jobsiteDefaultValues = getJobsiteInfoSectionDefaultValues(
    jobsite,
    developersOptions,
    jobsitesOptions,
    formsOptions,
  );
  const onboarding = getOnboardingSectionDefaultValues(jobsite);
  const features = getFeaturesSectionDefaultValues(jobsite);
  const workerInfo = getWorkerInfoSectionDefaultValues(jobsite);
  const siteSpecificOrientation = getSiteSpecificOrientationSectionDefaultValues(jobsite);
  const documents = getDocumentsSectionDefaultValues(jobsite);
  const siteAccessRules = getSiteAccessRulesSectionDefaultValues(jobsite, sessionDisplayColumnOptions);
  const notifications = getNotificationsSectionDefaultValues(jobsite);
  const geofencing = getGeofencingSectionDefaultValues(jobsite);
  const hidFargoConnect = getHidFargoConnectSectionDefaultValues(
    jobsite,
    hidProxyInstanceOptions,
    hidOrganizationOptions,
    hidLocationOptions,
    hidPrinterOptions,
    badgeTemplateOptions,
  );
  const cCure = getCCureSectionDefaultValues(
    jobsite,
    workerCardFormatsOptions,
    cCureControllersOptions,
    cCureDoorsOptions,
    cCureInboundAreasOptions,
    cCureOutboundAreasOptions,
  );
  const procore = getProcoreSectionDefaultValues(jobsite);
  const customFields = getCustomFieldsDefaultValues(jobsite);
  const cameras = getCameraSectionDefaultValues(jobsite);

  return {
    jobsite: jobsiteDefaultValues,
    onboarding,
    features,
    workerInfo,
    siteSpecificOrientation,
    documents,
    siteAccessRules,
    notifications,
    geofencing,
    hidFargoConnect,
    cCure,
    procore,
    cameras,
    customFields,
  };
};

export const getOnboardingModuleInput = (
  jobsite: Jobsite,
  data: EditJobsiteConfigurationFormData,
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData, true>,
): JobsiteUpdateInput['onboardingModule'] => {
  const { personalInfoStep } = getWorkerInfoSectionUpdateInput(data.workerInfo, dirtyFields.workerInfo);
  const { siteSpecificOrientationStep } = getSiteSpecificOrientationSectionUpdateInput(
    data.siteSpecificOrientation,
    dirtyFields.siteSpecificOrientation,
  );

  const fieldsByStepKey = {
    [OnboardingStepKey.SiteSpecificOrientation]: siteSpecificOrientationStep?.fields,
  };
  const { steps: otherSteps } = getOnboardingSectionUpdateInput(
    data.onboarding,
    dirtyFields.onboarding,
    fieldsByStepKey,
  );
  const { documents } = getOnboardingModuleDocumentInputs(data.documents, dirtyFields.documents);

  const steps = [personalInfoStep, ...otherSteps].filter(Boolean);
  const hasChanged = Boolean(steps?.length || documents?.length);

  return hasChanged
    ? {
        name: `${jobsite.name} - onboarding`,
        slug: `${titleToKebabCase(jobsite.name)}-onboarding`,
        isEnabled: true,
        steps,
        documents,
      }
    : undefined;
};

export const getAppCheckInModuleInput = (
  jobsite: Jobsite,
  data: EditJobsiteConfigurationFormData,
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData, true>,
): JobsiteUpdateInput['appCheckInModule'] => {
  const updateInput = getGeofencingSectionUpdateInput(data.geofencing, dirtyFields.geofencing);
  const hasChanged = isNotEmpty(updateInput);

  return hasChanged
    ? {
        name: `${jobsite.name} - Check In`,
        slug: 'check-in',
        isEnabled: true,
        ...updateInput,
      }
    : undefined;
};

export const getFeaturesModuleInput = (
  jobsite: Jobsite,
  data: EditJobsiteConfigurationFormData,
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData, true>,
): JobsiteUpdateInput['featuresModule'] => {
  if (dirtyFields.features) {
    const featuresSectionUpdateInput = getFeaturesSectionUpdateInput(data.features, dirtyFields.features);
    const moduleName = `${jobsite.name} - features`;
    return {
      name: moduleName,
      slug: slugify(moduleName, { lower: true, trim: true }),
      isEnabled: true,
      ...featuresSectionUpdateInput,
    };
  }
  return undefined;
};

export const getJobsiteUpdateInput = async (
  jobsite: Jobsite,
  data: EditJobsiteConfigurationFormData,
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData, true>,
): Promise<JobsiteUpdateInput> => {
  return ensureNonUndefinedFields<JobsiteUpdateInput>({
    jobsiteId: jobsite.jobsiteId,
    ...getJobsiteInfoSectionUpdateInput(data.jobsite, dirtyFields.jobsite),
    ...getSiteAccessRulesSectionUpdateInput(data.siteAccessRules, dirtyFields.siteAccessRules),
    ...getNotificationsSectionUpdateInput(data.notifications, dirtyFields.notifications),
    ...getHidFargoConnectSectionUpdateInput(data.hidFargoConnect, dirtyFields.hidFargoConnect),
    ...getCCureSectionUpdateInput(data.cCure, dirtyFields.cCure),
    ...getCameraSectionUpdateInput(data.cameras, dirtyFields.cameras),
    ...getProcoreSectionUpdateInput(data.procore, dirtyFields.procore),
    ...getCustomFieldsSectionUpdateInput(data.customFields, dirtyFields.customFields),
    onboardingDocumentFiles: await getOnboardingDocumentFilesInputs(jobsite, data.documents, dirtyFields.documents),
    onboardingModule: getOnboardingModuleInput(jobsite, data, dirtyFields),
    featuresModule: getFeaturesModuleInput(jobsite, data, dirtyFields),
    appCheckInModule: getAppCheckInModuleInput(jobsite, data, dirtyFields),
  });
};
