import cn from 'classnames';

export const classes = {
  container: (withBottomBorder: boolean, hideOnMobile: boolean): string =>
    cn(
      'odin-space-y-2 odin-px-7.5 odin-pt-7.5 sm:odin-px-0 sm:odin-pt-0',
      withBottomBorder && 'sm:odin-pb-6 sm:odin-mb-6 sm:odin-border-b odin-border-gray-200',
      hideOnMobile && 'odin-hidden sm:odin-block',
    ),
  title: 'odin-text-1.5xl',
  subtitle: (hideOnMobile: boolean): string => cn('odin-text-sm', hideOnMobile && 'odin-hidden sm:odin-block'),
};
