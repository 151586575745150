import {
  AlertProps,
  Icon,
  CleaveOptions,
  InputSize,
  InputType as FieldType,
  ModalProps,
  RadioGroupOption,
  SelectOptionElement,
  ToggleAlignment,
  CheckListOption,
  ButtonTheme,
  HTMLButtonProps,
} from '@odin-labs/components';
import { DropzoneProps } from 'components/dropzone';
import { HtmlRendererProps } from 'components/htmlRenderer';
import { JobsiteWorkerDocumentUploadProps } from 'components/jobsiteWorkerDocument/types';
import { LoadingErrorProps } from 'components/loadingError';
import { Place } from 'components/placesAutocomplete/types';
import { PlayerProps } from 'components/player';
import { SectionProps } from 'components/section';
import { Moment } from 'moment';
import { ReactElement, ReactNode } from 'react';
import {
  UseFormMethods as UseReactHookFormMethods,
  ValidateResult,
  RegisterOptions,
  UnpackNestedValue,
  DeepMap,
  DefaultValues,
  ErrorOption,
  DeepPartial,
  SetValueConfig,
  LiteralToPrimitive,
  FieldName,
  SetFieldValue,
} from 'react-hook-form';
import { StringEnum } from 'types';
import { Localize } from 'utils/localization';

type NullableReactElement = React.ReactElement | null;

export enum FormInputTypes {
  DateTimepicker = 'DateTimepicker',
  Toggle = 'Toggle',
  CustomContent = 'CustomContent',
  CustomInput = 'CustomInput',
  Layout = 'Layout',
  Signature = 'Signature',
  JobsiteWorkerDocument = 'JobsiteWorkerDocument',
  Field = 'Field',
  TextAreaField = 'TextAreaField',
  Select = 'Select',
  CreatableSelect = 'CreatableSelect',
  Section = 'Section',
  Panel = 'Panel',
  DatePicker = 'DatePicker',
  PlacesAutocomplete = 'PlacesAutocomplete',
  RadioGroup = 'RadioGroup',
  Dropzone = 'Dropzone',
  Alert = 'Alert',
  Weather = 'Weather',
  Player = 'Player',
  HtmlRenderer = 'HtmlRenderer',
  CheckList = 'CheckList',
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type FormData = {};

export interface FormDefaultValue<TFields> {
  name: keyof TFields;
  value: any;
}

export type FormDefaultValues<TFields extends FormData> = DefaultValues<TFields>;

export type UseFormMethods<TFields extends FormData> = Omit<
  UseReactHookFormMethods<TFields>,
  'watch' | 'setError' | 'setValue' | 'getValues'
> & {
  watch(): UnpackNestedValue<TFields>;
  watch<TFieldName extends keyof TFields>(
    name?: TFieldName,
    defaultValue?: FormDefaultValues<TFields>,
  ): UnpackNestedValue<TFields[TFieldName]>;
  watch<TFieldName extends string, TFieldValue>(
    name?: TFieldName,
    defaultValue?: TFieldName extends keyof TFields
      ? UnpackNestedValue<TFields[TFieldName]>
      : UnpackNestedValue<LiteralToPrimitive<TFieldValue>>,
  ): TFieldName extends keyof TFields
    ? UnpackNestedValue<TFields[TFieldName]>
    : UnpackNestedValue<LiteralToPrimitive<TFieldValue>>;
  watch<TFieldName extends keyof TFields>(
    names: TFieldName[],
    defaultValues?: UnpackNestedValue<DeepPartial<Pick<TFields, TFieldName>>>,
  ): UnpackNestedValue<Pick<TFields, TFieldName>>;
  watch(
    names: string[],
    defaultValues?: UnpackNestedValue<DeepPartial<TFields>>,
  ): UnpackNestedValue<DeepPartial<TFields>>;
  setError(name: keyof TFields, error: ErrorOption | { message: React.ReactNode; shouldFocus?: boolean }): void;
  setValue<TFieldName extends keyof TFields>(
    name: TFieldName,
    value: TFields[TFieldName],
    config?: SetValueConfig,
  ): void;
  setValue(name: FieldName<TFields>, value: SetFieldValue<TFields>, config?: SetValueConfig): void;
  getValues(): UnpackNestedValue<TFields>;
  getValues<TFieldName extends keyof TFields>(name: TFieldName): TFields[TFieldName];
  getValues<TFieldName extends keyof TFields>(names: TFieldName[]): UnpackNestedValue<Pick<TFields, TFieldName>>;
};

export type CustomValidate<TFields> = (
  data: any,
  formValues?: UnpackNestedValue<TFields>,
  form?: UseFormMethods<TFields>,
) => ValidateResult | Promise<ValidateResult>;

export type CustomRegisterOptions<TFields> = Omit<RegisterOptions, 'validate'> & {
  validate?: CustomValidate<TFields> | Record<string, CustomValidate<TFields>>;
};

type IconProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

export type OnBeforeChangeHandler = (value: any) => Promise<any>;

type CustomInputProps = {
  value?: any;
  onChange?: (value: any) => void;
  error?: React.ReactNode;
  autoFocus?: boolean;
};

type FormInputElementProps<TFields> = {
  label?: string;
  hideLabel?: boolean;
  labelIcon?: string;
  labelIconTooltipText?: string;
  subtitle?: string;
  checked?: boolean;
  toggleDescription?: string;
  toggleLabel?: string | Array<string>;
  placeholder?: string;
  fieldType?: FieldType;
  buttonType?: 'button' | 'submit';
  options?:
    | SelectOptionElement[]
    | RadioGroupFormOption<Partial<TFields>, string | number>[]
    | CheckListFormOption<Partial<TFields>, string | number>[];
  color?: string;
  onClick?: () => void;
  disabled?: boolean;
  datepickerDisabled?: 'startDate' | 'endDate';
  numberOfMonths?: number;
  showDefaultIcon?: boolean;
  content?: ReactNode;
  accept?: DropzoneProps['accept'];
  sourceType?: DropzoneProps['sourceType'];
  isMulti?: boolean;
  isSearchable?: boolean;
  title?: string;
  clearToNull?: boolean;
  onBeforeChange?: OnBeforeChangeHandler;
  onChange?: (value: any) => void;
  onValueChange?: (value: string, form?: UseFormMethods<TFields>) => void;
  onCommit?: <T = string | Place>(value: T, form?: UseFormMethods<TFields>) => void;
  dates?: Array<any>;
  isRange?: boolean;
  showUploadModal?: boolean;
  isCreateable?: boolean;
  upperCase?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  maxDate?: Moment;
  minDate?: Moment;
  icon?: (props: IconProps) => ReactElement;
  customInput?: (props: CustomInputProps) => ReactElement;
  maxLength?: number;
  showLengthCounterNote?: boolean;
  uploadEntirePdf?: boolean;
  cleaveOptions?: CleaveOptions;
  autoFocus?: boolean;
  preventSubmitOnEnter?: boolean;
  isClearable?: boolean;
  size?: InputSize;
  layout?: 'horizontal' | 'vertical';
  note?: React.ReactNode;
  toggleAlignment?: ToggleAlignment;
  innerRightLabel?: React.ReactNode;
  autosize?: boolean;
  maxRows?: number;
  isAvatar?: boolean;
  inputMode?: JSX.IntrinsicElements['input']['inputMode'];
  previewUrl?: string;
  preventLeadingZeros?: boolean;
};

export type FormElementName<TFields> = Extract<keyof TFields, string>;

type FormElement<TFields> = {
  name: FormElementName<TFields>;
  label?: string;
  labelCopy?: string;
} & (
  | {
      element: Exclude<FormInputTypes, FormInputTypes.Section | FormInputTypes.Panel>;
      elementProps?: FormInputElementProps<TFields>;
    }
  | {
      element: FormInputTypes.Section;
      elementProps?: SectionProps;
    }
  | {
      element: FormInputTypes.Panel;
      elementProps?: SectionProps;
    }
  | {
      element: FormInputTypes.JobsiteWorkerDocument;
      elementProps?: JobsiteWorkerDocumentUploadProps;
    }
  | {
      element: FormInputTypes.Player;
      elementProps?: PlayerProps;
    }
  | {
      element: FormInputTypes.HtmlRenderer;
      elementProps?: HtmlRendererProps;
    }
);

export enum GridColSpan {
  SpanFull = 'odin-col-span-full',
  Span1 = 'odin-col-span-1',
  Span2 = 'odin-col-span-2',
  Span3 = 'odin-col-span-3',
  Span4 = 'odin-col-span-4',
  Span5 = 'odin-col-span-5',
  Span6 = 'odin-col-span-6',
  Span7 = 'odin-col-span-7',
  Span8 = 'odin-col-span-8',
  Span9 = 'odin-col-span-9',
  Span10 = 'odin-col-span-10',
  Span11 = 'odin-col-span-11',
  Span12 = 'odin-col-span-12',
  SmSpan1 = 'sm:odin-col-span-1',
  SmSpan2 = 'sm:odin-col-span-2',
  SmSpan3 = 'sm:odin-col-span-3',
  SmSpan4 = 'sm:odin-col-span-4',
  SmSpan5 = 'sm:odin-col-span-5',
  SmSpan6 = 'sm:odin-col-span-6',
  SmSpan7 = 'sm:odin-col-span-7',
  SmSpan8 = 'sm:odin-col-span-8',
  SmSpan9 = 'sm:odin-col-span-9',
  SmSpan10 = 'sm:odin-col-span-10',
  SmSpan11 = 'sm:odin-col-span-11',
  SmSpan12 = 'sm:odin-col-span-12',
  MdSpan1 = 'md:odin-col-span-1',
  MdSpan2 = 'md:odin-col-span-2',
  MdSpan3 = 'md:odin-col-span-3',
  MdSpan4 = 'md:odin-col-span-4',
  MdSpan5 = 'md:odin-col-span-5',
  MdSpan6 = 'md:odin-col-span-6',
  MdSpan7 = 'md:odin-col-span-7',
  MdSpan8 = 'md:odin-col-span-8',
  MdSpan9 = 'md:odin-col-span-9',
  MdSpan10 = 'md:odin-col-span-10',
  MdSpan11 = 'md:odin-col-span-11',
  MdSpan12 = 'md:odin-col-span-12',
  XlSpan1 = 'xl:odin-col-span-1',
  XlSpan2 = 'xl:odin-col-span-2',
  XlSpan3 = 'xl:odin-col-span-3',
  XlSpan4 = 'xl:odin-col-span-4',
  XlSpan5 = 'xl:odin-col-span-5',
  XlSpan6 = 'xl:odin-col-span-6',
  XlSpan7 = 'xl:odin-col-span-7',
  XlSpan8 = 'xl:odin-col-span-8',
  XlSpan9 = 'xl:odin-col-span-9',
  XlSpan10 = 'xl:odin-col-span-10',
  XlSpan11 = 'xl:odin-col-span-11',
  XlSpan12 = 'xl:odin-col-span-12',
}

export type FormFieldConfig<T extends string = string> = {
  key: T;
  name?: string;
  index?: number;
  isRequired?: boolean;
  isHidden?: boolean;
};

export type FormFieldsConfig = {
  fields: Record<string, FormFieldConfig>;
  /*
   * Map between `input.name` and `config.field.key` when they differ.
   * FormFieldConfig can be used for overriding specific fields, matching by key.
   */
  inputToFieldMap?: Record<string, string | FormFieldConfig>;
  /** If not nullish, config will be applied only for specified input names */
  inputsFilter?: string[];
};

export type FormLocalization = {
  localize: Localize;
  copy: StringEnum;
};

export type FormInput<TFields> = {
  validation?: CustomRegisterOptions<TFields> | null;
  layout: string | string[] | GridColSpan | ((args: { visibleFields: string[] }) => string | string[]);
  files?: FormInput<TFields>[];
  children?: FormInput<TFields>[] | TypedFormInputs<TFields>;
  defaultValue?: string;
  saveValue?: string;
  isHidden?: boolean;
  loading?: boolean;
} & FormElement<TFields>;

export type FormOnSubmit<TFields> = (
  data: UnpackNestedValue<TFields>,
  event: React.BaseSyntheticEvent,
  dirtyFields: DeepMap<TFields, true>,
  methods: UseFormMethods<TFields>,
) => void;

// type Convert<V, O extends object> = {
//   [K in keyof O]: O[K] extends object ? V & { children?: Convert<V, O[K]> } : V;
// };

type RecursiveFormInputs<TFormData extends object> = {
  [K in keyof TFormData]: TFormData[K] extends Record<string, unknown>
    ? Omit<FormInput<TFormData[K]>, 'name' | 'children'> & {
        children?:
          | RecursiveFormInputs<TFormData[K]>
          | ((form?: UseFormMethods<TFormData[K]>) => FormInput<TFormData[K]>[] | RecursiveFormInputs<TFormData[K]>);
      }
    : Omit<FormInput<TFormData>, 'name'>;
};

export type TypedFormInputs<TFormData extends FormData> = RecursiveFormInputs<TFormData>;

export type UseInputs<TFields extends FormData> = (
  form?: UseFormMethods<TFields>,
) => FormInput<TFields>[] | TypedFormInputs<TFields>;

export type UseFormBuilderResult<TFields extends FormData> = {
  formOnSubmit: (data: UnpackNestedValue<TFields>, event: React.BaseSyntheticEvent) => void;
  formElements: JSX.Element[];
};

export type UseFormBuilderArgs<TFields extends FormData> = {
  methods: UseFormMethods<TFields>;
  defaultValues: FormDefaultValues<TFields>;
  /**
   * Theses changes will be applied after the form is initialized using defaultValues.
   * The changed fields will be set as dirty.
   * It can be used for the use case when some form fields need to be initialized as dirty,
   */
  changedValues?: FormDefaultValues<TFields>;
  inputs: FormInput<TFields>[] | TypedFormInputs<TFields> | UseInputs<TFields>;
  onSubmit: FormOnSubmit<TFields>;
  className?: string;
  inputsContainerClassName?: string;
  onIsDirtyChange?: (isDirty: boolean) => void;
  /** Provide here any values whose change will trigger the form validation. */
  validationTriggers?: any[];
  /** It will focus the specified field. If `true` it will focus the first input. */
  autoFocus?: boolean | keyof TFields;
  fieldsConfig?: FormFieldsConfig;
  localization?: FormLocalization;
};

export type FormProps<TFields extends FormData> = Omit<UseFormBuilderArgs<TFields>, 'methods' | 'defaultValues'> & {
  defaultValues?: FormDefaultValues<TFields> | FormDefaultValue<TFields>[];
  renderAbove?: ReactNode;
  renderBelow?: ReactNode;
};

export type ModalFormProps<TFields extends FormData> = Omit<
  FormProps<TFields>,
  | 'autoFocus' // this is handled by the Modal component
  | 'renderBelow'
  | 'className'
> &
  Omit<ModalProps, 'children' | 'actionsPanel' | 'actionButtonType' | 'cancelButtonType' | 'className'> & {
    actionsContainerClassName?: string;
    alertProps?: AlertProps;
  } & Pick<LoadingErrorProps, 'loading' | 'error'>;

export type BackButtonProps = {
  backButtonType?: HTMLButtonProps['type'];
  backButtonTheme?: ButtonTheme;
  backButton?: <P extends HTMLButtonProps>(props: P) => NullableReactElement;
  backText?: string;
  backButtonEnabled?: boolean;
  backButtonWithSpinner?: boolean;
  backIcon?: Icon;
};

export type SequencedModalFormProps<TFields extends FormData> = Omit<FormProps<TFields>, 'className'> &
  Omit<ModalProps, 'children' | 'actionsPanel' | 'actionButtonType' | 'cancelButtonType' | 'className'> & {
    actionsContainerClassName?: string;
  } & Pick<LoadingErrorProps, 'loading' | 'error'> & { onBack: () => void } & BackButtonProps;

export type ModalFormContentProps<TFields extends FormData> = Omit<
  ModalFormProps<TFields>,
  'open' | 'title' | 'subtitle' | 'size' | 'titleAlignment' | 'textAlignment' | 'afterLeave' | 'overlayCloseDisabled'
>;

export type RadioGroupFormOption<
  TFields extends FormData,
  TOption extends string | number = string,
> = RadioGroupOption<TOption> & {
  /**
   * An array containing form inputs which will be render as RadioGroupOption children
   */
  inputs?: FormInput<TFields>[];
  /**
   * Classes which will be applied to a div container
   */
  inputsContainerLayout?: string;
  inputsVisibility?: 'whenChecked' | 'always';
};

export type CheckListFormOption<
  TFields extends FormData,
  TOption extends string | number = string,
> = CheckListOption<TOption> & {
  /**
   * An array containing form inputs which will be render as RadioGroupOption children
   */
  inputs?: FormInput<TFields>[];
  /**
   * Classes which will be applied to a div container
   */
  inputsContainerLayout?: string;
  inputsVisibility?: 'whenChecked' | 'always';
};

export type HTMLFormElementWithApi<TFields extends FormData> = HTMLFormElement & {
  api: UseFormMethods<TFields>;
};

export type ApplyLocalizationArgs<TFormData extends FormData> = {
  inputs: FormInput<TFormData>[];
  localization: FormLocalization;
};

export type ApplyConfigArgs<TFormData extends FormData> = {
  inputs: FormInput<TFormData>[];
  config: FormFieldsConfig;
};
