import { JobsiteUpdateInput } from 'apollo/generated/client-operations';
import { FormInputTypes, getUpdateInputValueFunction, GridColSpan, TypedFormInputs } from 'components/form';
import { EditJobsiteConfigurationFormData, Jobsite } from 'containers/jobsiteConfiguration/types';
import { DeepMap } from 'react-hook-form';
import { arraysEqual } from 'utils/arrays';
import {
  jobTitleOptions as defaultTitleOptions,
  toFancySelectOptions,
  tradeOptions as defaultTradeOptions,
  FancySelectOption,
} from 'utils/constants';

/**
 * Returns a distinct array of FancySelectOption from the provided existing value and default options.
 */
const getOptions = (value: string[], defaultOptions: string[]): FancySelectOption[] => {
  return toFancySelectOptions([...new Set([...(Array.isArray(value) ? value : []), ...defaultOptions])]);
};

export const customFieldsSectionInputs = (
  jobsite: Jobsite,
): TypedFormInputs<EditJobsiteConfigurationFormData['customFields']> => {
  const { tradeOptions: savedTradeOptions, titleOptions: savedTitleOptions } = jobsite ?? {};

  return {
    tradeOptions: {
      element: FormInputTypes.CreatableSelect,
      label: 'Trade Options',
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
      elementProps: {
        isMulti: true,
        clearToNull: true,
        options: getOptions(savedTradeOptions, defaultTradeOptions),
      },
    },
    titleOptions: {
      element: FormInputTypes.CreatableSelect,
      label: 'Title Options',
      layout: [GridColSpan.SpanFull, GridColSpan.SmSpan6],
      elementProps: {
        isMulti: true,
        clearToNull: true,
        options: getOptions(savedTitleOptions, defaultTitleOptions),
      },
    },
  };
};

export const getCustomFieldsDefaultValues = (jobsite: Jobsite): EditJobsiteConfigurationFormData['customFields'] => {
  const { tradeOptions, titleOptions } = jobsite ?? {};
  return {
    // If the jobsite has not configured custom trade or title options,
    // the jobsite will use the default options.
    tradeOptions: toFancySelectOptions(tradeOptions ?? defaultTradeOptions),
    titleOptions: toFancySelectOptions(titleOptions ?? defaultTitleOptions),
  };
};

type CustomFieldsSectionUpdateInput = Required<Pick<JobsiteUpdateInput, 'titleOptions' | 'tradeOptions'>>;

export const getCustomFieldsSectionUpdateInput = (
  customFields: EditJobsiteConfigurationFormData['customFields'],
  dirtyFields: DeepMap<EditJobsiteConfigurationFormData['customFields'], true>,
): CustomFieldsSectionUpdateInput => {
  const getUpdateInputValue = getUpdateInputValueFunction(customFields, dirtyFields);

  const titleOptionsValue = getUpdateInputValue('titleOptions');
  const tradeOptionsValue = getUpdateInputValue('tradeOptions');

  return {
    // If options fields match the default options, we store null. We only save the values if they are custom.
    titleOptions: arraysEqual(titleOptionsValue, defaultTitleOptions) ? null : titleOptionsValue,
    tradeOptions: arraysEqual(tradeOptionsValue, defaultTradeOptions) ? null : tradeOptionsValue,
  };
};
